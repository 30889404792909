import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/docs/src/components/layout.tsx";
import { Dskus } from '../components/examples/dskus';
import { Skus } from '../components/examples/skus';
import { Note } from '../components/note';
import { signUpFormUrl } from '../components/constants';
import { Indent } from '../components/indent';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: Guide
description: Learn how to setup Flexport fast tags for your online storefront.`}</p>



    <h2 {...{
      "id": "guide",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#guide",
        "aria-label": "guide permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Guide`}</h2>
    <Note headerText="Deliverr is undergoing a rebrand to Flexport. API code will still have `Deliverr` in the meantime." mdxType="Note" />
    <p>{`Flexport Website Fast Tags is a way of quickly and easily integrating Flexport's fast tags into an online storefront. This lets online shoppers know whether or not fast shipping, ie. one, two or three day, is available for a product in their area.`}</p>
    <Note headerText="Before you get started" mdxType="Note">
  <div>
    Please make sure that you've already <a href={signUpFormUrl}>signed up</a>{' '}
    for our Website Fast Tags program and received your seller id, as you'll
    need that to move forward.
  </div>
    </Note>
    <h3 {...{
      "id": "outline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outline",
        "aria-label": "outline permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outline`}</h3>
    <p>{`There are a few steps we'll need to take to complete integration of Flexport Website Fast Tags onto your storefront:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#including-the-library"
        }}>{`Including the library`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#adding-the-badge-component"
        }}>{`Adding the badge component`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#displaying-the-offer-at-checkout"
        }}>{`Displaying the offer at checkout`}</a></li>
    </ol>
    <p>{`You `}<strong parentName="p">{`MUST`}</strong>{` implement both on your store in order to display fast tags, offer the correct shipping option at checkout, and receive Flexport's discounted fast tag 2-Day rates.`}</p>
    <h3 {...{
      "id": "including-the-library",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#including-the-library",
        "aria-label": "including the library permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Including the Library`}</h3>
    <p>{`Add the library to the storefront by placing the following `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`script`}</code>{` tag onto any pages that want to use the website fast tags library:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<script src="https://fast-tags.flexport.com/web/main.js"></script>`}</code></pre></div>
    <p>{`Place this somewhere within the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`head`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`body`}</code>{` tag.`}</p>
    <h3 {...{
      "id": "adding-the-badge-component",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-the-badge-component",
        "aria-label": "adding the badge component permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the Badge Component`}</h3>
    <p>{`There are two main methods of adding the fast tags to a page:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "/reference#deliverr-tag"
          }}>{`deliverr-tag`}</a>{` component: This adds a `}<em parentName="p">{`fast tag badge`}</em>{` that matches the service level available for the product(s) in the customer's location. This is something that fits well on pages with lists of products.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "/reference#deliverr-tag-extended"
          }}>{`deliverr-tag-extended`}</a>{` component: This
adds a `}<em parentName="p">{`fast tag badge`}</em>{` that matches the service level available for for the product(s)
in the customer's location along with:`}</p>
      </li>
    </ol>
    <Indent mdxType="Indent">
  <ul>
    <li>
      a <em>zip code form</em> where a visitor can manually enter override our
      auto-detected location.
    </li>
    <li>when a package is expected to arrive</li>
  </ul>
  <p>
    The extended badge is something that fits well on product detail pages where
    a customer would like more information on a product and is closer to adding
    the product to the cart.
  </p>
    </Indent>
    <p>{`Add either the simple or extended tag html as shown below where you would like the tag to be placed on the page.`}</p>
    <h4 {...{
      "id": "simple",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#simple",
        "aria-label": "simple permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h4">{`Simple`}</strong></h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "751px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "3.191489361702128%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAJklEQVQI12P4d+fS/38Prv//d/vi///P7///9////////kEwGQAASBdOiM0P7dwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "deliverr-tag",
          "title": "deliverr-tag",
          "src": "/static/9723ed18cd61b4dabba9608851f23d79/c483d/deliverr-tag.png",
          "srcSet": ["/static/9723ed18cd61b4dabba9608851f23d79/4dcb9/deliverr-tag.png 188w", "/static/9723ed18cd61b4dabba9608851f23d79/d38a6/deliverr-tag.png 376w", "/static/9723ed18cd61b4dabba9608851f23d79/c483d/deliverr-tag.png 751w", "/static/9723ed18cd61b4dabba9608851f23d79/dcccd/deliverr-tag.png 1127w", "/static/9723ed18cd61b4dabba9608851f23d79/7321b/deliverr-tag.png 1184w"],
          "sizes": "(max-width: 751px) 100vw, 751px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-code-button-container",
      "data-toaster-id": "",
      "data-toaster-class": "gatsby-code-button-toaster",
      "data-toaster-text-class": "gatsby-code-button-toaster-text",
      "data-toaster-text": "",
      "data-toaster-duration": "3500",
      "onClick": "copyToClipboard(`<deliverr-tag></deliverr-tag>`, ``)"
    }}>{`
              `}<div parentName="div" {...{
        "className": "gatsby-code-button",
        "data-tooltip": ""
      }}>{`
                `}<svg parentName="div" {...{
          "className": "gatsby-code-button-icon",
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24"
        }}><path parentName="svg" {...{
            "fill": "none",
            "d": "M0 0h24v24H0V0z"
          }}></path><path parentName="svg" {...{
            "d": "M16 1H2v16h2V3h12V1zm-1 4l6 6v12H6V5h9zm-1 7h5.5L14 6.5V12z"
          }}></path></svg>{`
              `}</div>{`
            `}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`deliverr-tag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`deliverr-tag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h4 {...{
      "id": "extended",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#extended",
        "aria-label": "extended permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extended`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "751px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "6.914893617021277%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsTAAALEwEAmpwYAAAARUlEQVQI103DOwqAMBBAQe9/QouAQS3SxN18TAjKPtHKgZmid8i6IJsnuJkz7NArokpK6auqlJw5WkVHI/bKuC9eZsbfA/WGTMj+LE+KAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "deliverr-tag-extended",
          "title": "deliverr-tag-extended",
          "src": "/static/003cc2b3713cd01d3fa9ec519241b101/c483d/deliverr-tag-extended.png",
          "srcSet": ["/static/003cc2b3713cd01d3fa9ec519241b101/4dcb9/deliverr-tag-extended.png 188w", "/static/003cc2b3713cd01d3fa9ec519241b101/d38a6/deliverr-tag-extended.png 376w", "/static/003cc2b3713cd01d3fa9ec519241b101/c483d/deliverr-tag-extended.png 751w", "/static/003cc2b3713cd01d3fa9ec519241b101/dcccd/deliverr-tag-extended.png 1127w", "/static/003cc2b3713cd01d3fa9ec519241b101/7321b/deliverr-tag-extended.png 1184w"],
          "sizes": "(max-width: 751px) 100vw, 751px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-code-button-container",
      "data-toaster-id": "",
      "data-toaster-class": "gatsby-code-button-toaster",
      "data-toaster-text-class": "gatsby-code-button-toaster-text",
      "data-toaster-text": "",
      "data-toaster-duration": "3500",
      "onClick": "copyToClipboard(`<deliverr-tag-extended></deliverr-tag-extended>`, ``)"
    }}>{`
              `}<div parentName="div" {...{
        "className": "gatsby-code-button",
        "data-tooltip": ""
      }}>{`
                `}<svg parentName="div" {...{
          "className": "gatsby-code-button-icon",
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24"
        }}><path parentName="svg" {...{
            "fill": "none",
            "d": "M0 0h24v24H0V0z"
          }}></path><path parentName="svg" {...{
            "d": "M16 1H2v16h2V3h12V1zm-1 4l6 6v12H6V5h9zm-1 7h5.5L14 6.5V12z"
          }}></path></svg>{`
              `}</div>{`
            `}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`deliverr-tag-extended`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`deliverr-tag-extended`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "connecting-product-info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connecting-product-info",
        "aria-label": "connecting product info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting product info`}</h3>
    <p>{`Both tags need to be connected to Flexport seller data before being able to determine fast shipping availability. For both the basic and extended tag, there are two methods of doing this:`}</p>
    <ol>
      <li parentName="ol">{`By adding the `}<a parentName="li" {...{
          "href": "/reference#deliverr-tag-data-dskus"
        }}>{`data-skus`}</a>{` attribute and the `}<a parentName="li" {...{
          "href": "/reference#deliverr-tag-data-sellerid"
        }}>{`data-sellerid`}</a>{` attribute onto the tag`}</li>
      <li parentName="ol">{`By adding the `}<a parentName="li" {...{
          "href": "/reference#deliverr-tag-data-dskus"
        }}>{`data-dskus`}</a>{` attribute onto the tag`}</li>
    </ol>
    <Note mdxType="Note">
  Both <code>data-skus</code> and <code>data-dskus</code> take arrays that must
  be valid JSON. For examples of valid JSON data, see the{' '}
  <a href="https://json.org/example.html">official site</a>.
    </Note>
    <p>{`Use the forms below to generate usable html using either `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-skus`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-dskus`}</code>{`.`}</p>
    <h4 {...{
      "id": "connecting-product-info-using-data-skus",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#connecting-product-info-using-data-skus",
        "aria-label": "connecting product info using data skus permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using data-skus`}</h4>
    <ol>
      <li parentName="ol">{`Type in the SKU(s) for all product variants this tag applies to, separating each SKU on its own line.`}</li>
      <li parentName="ol">{`Type your seller id as provided to you by Flexport.`}</li>
    </ol>
    <Skus mdxType="Skus" />
    <h4 {...{
      "id": "connecting-product-info-using-data-dskus",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#connecting-product-info-using-data-dskus",
        "aria-label": "connecting product info using data dskus permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using data-dskus`}</h4>
    <ol>
      <li parentName="ol">{`Type in the Flexport SKU(s) for all product variants this tag applies to, separating each SKU on its own line. Flexport SKUs (DSKUs) can be found in `}<a parentName="li" {...{
          "href": "https://portal.flexport.com"
        }}>{`Flexport's seller portal`}</a>{`.`}</li>
    </ol>
    <Dskus mdxType="Dskus" />
    <h3 {...{
      "id": "displaying-the-offer-at-checkout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#displaying-the-offer-at-checkout",
        "aria-label": "displaying the offer at checkout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Displaying the Offer at Checkout`}</h3>
    <p>{`When a customer checks out, the website will need to retrieve the shipping option available for the cart. This is done through `}<a parentName="p" {...{
        "href": "/reference#getshippingoptions"
      }}>{`getShippingOptions`}</a>{`. Pass the customer's full address along with the sku/dsku data`}</p>
    <h4 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`window.DeliverrApi.getShippingOptions({
  destination: {
    street1: "760 Market Street",
    street2: "8th Floor",
    zip: "94102",
    city: "San Francisco",
    state: "CA",
    country: "US"
  },
  skus: [
    "sku127"
  ],
  sellerId: "flexport-sellerId"
})`}</code></pre></div>
    <h3 {...{
      "id": "configuring-the-application",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuring-the-application",
        "aria-label": "configuring the application permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring the Application`}</h3>
    <p>{`The Flexport fast tags library runs with intuitive defaults to minimize the amount of configuration necessary to get started, but some features are opt-in and need to be set. To do this, assign a value to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DeliverrFastTags`}</code>{` property on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`window`}</code>{` global:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`const myConfig = {}

window.DeliverrFastTags = {
  appConfig: myConfig;
}`}</code></pre></div>
    <p>{`Where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`appConfig`}</code>{` are your desired configuration settings.`}</p>
    <Note headerText="Important" mdxType="Note">
  <div>
    For the fast tags library to read your configurations, the
    `DeliverrFastTags` object must be set <b>before</b> the script loading our
    library on the page.
  </div>
    </Note>
    <h3 {...{
      "id": "configuring-cart-minimums",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuring-cart-minimums",
        "aria-label": "configuring cart minimums permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring Cart Minimums`}</h3>
    <p>{`Before getting started, it's important to know that the cart minimum value is managed within the Flexport systems, if you haven't already set a cart minimum value, please contact a `}<a parentName="p" {...{
        "href": "https://support.portal.flexport.com/hc/en-us/requests/new"
      }}>{`Flexport customer representative`}</a>{`. The Flexport fast tags library allows you to show the fast delivery badge along with a message letting your visitors know that their cart needs to hit a certain amount before being eligible for fast shipping. This is by `}<strong parentName="p">{`off`}</strong>{` by default, so we'll need to add a couple of things to our configuration:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`window.DeliverrFastTags = {
  appConfig: {
    cartMinimum: true,
    sellerId: 'my-flexport-provided-seller-id',
  }
}`}</code></pre></div>
    <p>{`Here in our app config, you can see that the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cartMinimum`}</code>{` property has been set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{`, telling the library that we want to show the badges with cart minimums in mind. For the fast tags library to be able to figure out what your cart minimum values have been set to, we also need to configure the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sellerId`}</code>{`. This is the same id that you'll see in `}<a parentName="p" {...{
        "href": "https://portal.flexport.com"
      }}>{`seller portal`}</a>{` and other Flexport apps.`}</p>
    <h3 {...{
      "id": "setting-a-cart-size-and-price",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#setting-a-cart-size-and-price",
        "aria-label": "setting a cart size and price permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting a cart size and price`}</h3>
    <p>{`Fast tags show the cart minimum message conditionally, and does this by checking if the `}<strong parentName="p">{`current cart size plus the product price of the fast tag`}</strong>{` meet the cart minimum in our system. When the cart minimum is met, it chooses to show the "order by" message, and when it isn't met, is shows the cart minimum value that needs to be met to qualify for fast shipping. The tag gets these two values from html attributes:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`<deliverr-tag-extended data-price="20" data-cart-size="45" />`}</code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-price`}</code>{` attribute, tells the library that the product represented by the fast tag is $20, and the current size of the cart is $45. If either value changes at some point due to a customer adding things to their order, or due to variant switches, these attributes need to be updated to reflect those changes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      